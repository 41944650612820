<template>
  <v-container>
    <v-row class="justify-start">
      <v-col class="mt-14" cols="12">
        <h2>403 - Access denied.</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AccessDeniedPage',
};
</script>
